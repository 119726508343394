import React from "react";
import { graphql } from "gatsby";
import { DefaultLayout } from "../layouts";
import { white } from "../utils";
import { Header, Background as SectionBackground, PageWrapper, ContentWrapper } from "../components";
import styled, { css }  from "styled-components";
import ReactMarkdownWithHtml from "react-markdown/with-html";

const SectionContentWrapper = styled(props => <ContentWrapper {...props} />)`
  margin: 0 !important;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SectionIcon = styled.img`
  max-width: 65px;
  width: 100%;
  align-self: flex-start;
  @media (max-width: 768px) {
    margin: 10px 25px;
  }
`;

const SectionContent = styled.div`
  padding: 0 25px;
  color: inherit;
  width: 100%;
  font-size: 10pt;

  ${props =>
    props.backdrop
      ? css`
          background-color: rgb(89,89,89, 0.8);
          padding-top: 20px;
          * {
            color: white !important;
          }
        `
      : ''};
`;

const SectionHeader = styled.h2`
  color: inherit;
  margin: 0;
  font-size: 14pt;
  font-weight: 500;
  padding-bottom: 10px;
`;

const SectionSubheading = styled.p`
  font-size: 10pt;
  white-space: pre-wrap;
  color: inherit;
`;

const FooterImage = styled.div`
  background-image: url('/assets/images/PRI_Sig_White-1.png'); 
  height: 170px;
  max-width: 400px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 0;
  margin-left: auto;
}
`;



export const AboutUsSection = ({image, icon, children, content, heading, footerImage}) => {
  return (
    <SectionBackground image={image}>
    <SectionContentWrapper>
      {icon ? <SectionIcon src={icon} /> : <></>}
      <SectionContent backdrop={!!image}>
        <SectionHeader>{heading}</SectionHeader>
        <SectionSubheading>
          <ReactMarkdownWithHtml children={content} allowDangerousHtml />
        </SectionSubheading>
        {children}

        {footerImage ? <FooterImage/> : <></>}
      </SectionContent>
    </SectionContentWrapper>
  </SectionBackground>);
};

const AboutPage = ({ data }) => {
  const { sections, header } = data.about;
  return (
    <DefaultLayout title="About Us">
      <SectionBackground image={data.logo.publicURL}>
          <Header color={white}>{header}</Header>
      </SectionBackground>
      <PageWrapper>
        {sections.map((section, index) => (
          <AboutUsSection {...section} key={index} footerImage={index === sections.length - 1}/>
        ))}
      </PageWrapper>
    </DefaultLayout>
  );
};

export const query = graphql`
  {
    about: aboutJson {
      header
      sections {
        content
        heading
        image
        icon
      }
    }
    logo: file(name: { eq: "about-us" }) {
      publicURL
    }
  }
`;

export default AboutPage;
